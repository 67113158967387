import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

import { default as IconInstagram } from "../components/icons/instagram"

class Nav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false
    };
		this.toggleMenu = this.toggleMenu.bind(this);
  }

  toggleMenu() {
    this.setState({ showMenu: !this.state.showMenu });
  }

  render() {
    return (
      <>
        <button onClick={this.toggleMenu} className="btn btn--subtle header__trigger js-header__trigger" aria-label="Toggle menu" aria-expanded={this.state.showMenu} aria-controls="header-nav">
					<i className="header__trigger-icon" aria-hidden="true"></i>
					<span>Menu</span>
				</button>

        <nav className={this.state.showMenu ? 'header__nav js-header__nav header__nav--is-visible' : 'header__nav js-header__nav'} id="header-nav" role="navigation" aria-label="Main">
					<div className="header__nav-inner">
						<div className="header__label">Main menu</div>
						{this.props.children}
					</div>
				</nav>
      </>
    )
  }
}

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      siteTitle: props.siteTitle
    };
  }

  componentDidMount() {
    document.getElementsByTagName("html")[0].className += " js";
  }

  render() {
    return (
      <header className="header js-header">
				<div className="header__container container max-width-lg">
					<div className="header__logo">
						<Link to="/">
							{this.state.siteTitle}
						</Link>
					</div>

					<Nav>
						<ul className="header__list">
              <li className="header__item">
                <a href="https://www.instagram.com/sassy_soubrette328/" className="header__link btn btn--icon padding-y-xxxs padding-x-xxxxs">
                  <IconInstagram />
                  <span className="hide@md margin-left-xxs">Instagram</span>
                </a>
              </li>
							<li className="header__item"><Link to="/#bio" className="header__link">Bio</Link></li>
              <li className="header__item"><Link to="/#press-quotes" className="header__link">Press Quotes</Link></li>
							<li className="header__item"><Link to="/#contact" className="header__link btn btn--primary">Contact</Link></li>
						</ul>
					</Nav>

				</div>
			</header>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
