import React from "react"
import PropTypes from "prop-types"

import Logo from "../svg/boldoakdesign.svg"

const Footer = ({ siteTitle }) => (
  <footer className="margin-top-lg margin-bottom-md">
    <div className="container max-width-lg text-sm">
      <div className="grid gap-xs">
        <div className="col-12 col-6@md text-center text-left@md flex justify-center justify-start@md items-center">
          <p>
            © {new Date().getFullYear()} {siteTitle}
          </p>
        </div>
        <div className="col-12 col-6@md text-center text-right@md">
          <p className="icon-text">
            <a href="https://boldoak.design" className="btn btn--icon">
							<span className="margin-right-xxs">Design by</span>
              <Logo className="icon icon--md icon--boldoakdesign radius-50%" />
              <span className="sr-only">Bold Oak Design</span>
            </a>
          </p>
        </div>
      </div>
    </div>
  </footer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
